import styled from 'styled-components';

import { LARGE_WIDTH, MEDIUM_WIDTH, SMALL_WIDTH } from '../../lib/size';

export const getWidthBySize = size =>
  (size === 'sm' && SMALL_WIDTH) || (size === 'md' && MEDIUM_WIDTH) || LARGE_WIDTH;

const MainContainer = styled.div`
  margin: 2em auto 0;
  width: ${({ size = 'sm' }) => getWidthBySize(size)}px;
`;

export default MainContainer;
