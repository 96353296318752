import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

const Text = styled.span`
  font-size: 12px;
  color: #666666;
`;

const langs = {
  address: {
    ja: '東京都渋谷区渋谷1-24-6 マトリクス・ツービル9F',
    en: '9F 1-24-6 Shibuya, Shibuya-ku, Tokyo',
  },
};

const Address = ({ lang = 'ja' }) => (
  <Flex column>
    <FlexItem>
      <Text>〒150-0002</Text>
    </FlexItem>
    <FlexItem>
      <Text>{langs.address[lang]}</Text>
    </FlexItem>
    <FlexItem>
      <Text>TEL：03-3400-3288</Text>
      <br />
      <Text>FAX：03-3400-3039</Text>
      <br />
      {/* <Text>Eメール:info@hirahata-clinic.or.jp</Text>*/}
    </FlexItem>
  </Flex>
);

export default Address;
