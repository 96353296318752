import React from 'react';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import IconButton from './IconButton';

const TwitterButton = ({ link = 'https://twitter.com/k_hirahata', size }) => (
  <IconButton
    size={size}
    link={link}
    external
    label="twitter"
    icon={faTwitter}
    buttonColor="#00aced"
  />
);

export default TwitterButton;
