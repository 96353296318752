import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faMapMarkerAlt,
  faBuilding,
  faEnvelope,
  faBars,
  faSyringe,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import Link from './Link';

const MenuFlex = styled(Flex)`
  height: ${({ size }) => (size === 'sm' && 60) || 100}px;
`;

const MenuBoxFlex = styled(Flex)`
  width: ${({ size }) => (size === 'sm' && 60) || 78}px;
  height: 60px;
`;

const IconTitle = styled.span`
  font-size: ${({ size }) => (size === 'sm' && 12) || 14}px;
  color: #333333;
`;

const FlexItemIconWrapper = styled(FlexItem)`
  ${({ size }) => size !== 'sm' && `margin-top: 16px;`}
`;

const HomeIcon = () => <FontAwesomeIcon icon={faHome} style={{ fontSize: 26 }} color="#f7b13c" />;
const MapIcon = () => (
  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ fontSize: 26 }} color="#f7b13c" />
);
const BuildingIcon = () => (
  <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 26 }} color="#f7b13c" />
);
const MailIcon = () => (
  <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 26 }} color="#f7b13c" />
);
const BarsIcon = () => <FontAwesomeIcon icon={faBars} style={{ fontSize: 26 }} color="#f7b13c" />;

const SyringeIcon = () => (
  <FontAwesomeIcon icon={faSyringe} style={{ fontSize: 26 }} color="#f7b13c" />
);
const QuestionIcon = () => (
  <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 26 }} color="#f7b13c" />
);

const Menu = ({ icon, title, size }) => (
  <MenuBoxFlex
    column
    center
    css={`
      cursor: pointer;
    `}
    size={size}
  >
    <FlexItem>{icon}</FlexItem>
    <FlexItem>
      <IconTitle size={size}>{title}</IconTitle>
    </FlexItem>
  </MenuBoxFlex>
);

const TopMenu = ({ onOpenSideMenu, size }) => (
  <MenuFlex column justifyCenter size={size}>
    <FlexItem>
      <Flex>
        <FlexItemIconWrapper size={size}>
          <Link to="/">
            <Menu icon={<HomeIcon />} title="HOME" size={size} />
          </Link>
        </FlexItemIconWrapper>
        <FlexItemIconWrapper size={size}>
          <Link to="/access">
            <Menu icon={<MapIcon />} title="アクセス" size={size} />
          </Link>
        </FlexItemIconWrapper>
        <FlexItemIconWrapper size={size}>
          <Link to="/schedule">
            <Menu icon={<BuildingIcon />} title="受付時間" size={size} />
          </Link>
        </FlexItemIconWrapper>
        <FlexItemIconWrapper size={size}>
          <Link to="/contact">
            <Menu icon={<MailIcon />} title="問い合わせ" size={size} />
          </Link>
        </FlexItemIconWrapper>
        <FlexItemIconWrapper size={size}>
          <div onClick={onOpenSideMenu}>
            <Menu icon={<BarsIcon />} title="MENU" size={size} />
          </div>
        </FlexItemIconWrapper>
      </Flex>
    </FlexItem>
  </MenuFlex>
);

export default TopMenu;
