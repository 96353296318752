import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import Link from './Link';
import { getWidthBySize } from './layouts/MainContainer';
import HomeBrand from './HomeBrand';
import TopMenu from './TopMenu';

const HeaderWrapper = styled.header`
  position: sticky;
  background-color: #ffffff;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  overflow-x: hidden;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  width: ${({ size }) => getWidthBySize(size)}px;
`;

const Header = ({ onOpenSideMenu, size }) => (
  <HeaderWrapper>
    <HeaderContainer size={size}>
      <Flex
        justifyBetween={size === 'lg' && true}
        justifyAround={size !== 'lg' && true}
        column={size === 'sm' && true}
      >
        <FlexItem>
          <Link to="/">
            <HomeBrand size={size} />
          </Link>
        </FlexItem>
        <FlexItem>
          <TopMenu onOpenSideMenu={onOpenSideMenu} size={size} />
        </FlexItem>
      </Flex>
    </HeaderContainer>
  </HeaderWrapper>
);

export default Header;
