import React from 'react';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import IconButton from './IconButton';

const FacebookButton = ({
  link = 'https://www.facebook.com/%E3%83%92%E3%83%A9%E3%83%8F%E3%82%BF%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF-145144595523543',
  size,
}) => (
  <IconButton
    size={size}
    link={link}
    external
    label="Facebook"
    icon={faFacebookF}
    buttonColor="#305097"
  />
);

export default FacebookButton;
