import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import Link from './Link';

const Wrapper = styled.div`
  background-color: rgba(102, 102, 102, 0.7);
  height: 100%;
  width: ${(props) => (props.open ? '100%' : 0)};
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.4s;
  opacity: ${(props) => (props.open ? 1 : 0)};
`;
const Container = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: ${(props) => (props.open ? '300px' : 0)};
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.4s;
  opacity: ${(props) => (props.open ? 1 : 0)};
`;

const ListItemText = styled.div`
  font-size: 18px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MenuItem = ({ name, to, external = false }) => (
  <FlexItem>
    <Link to={to} external={external}>
      <Flex center>
        <FlexItem>
          <ListItemText>{name}</ListItemText>
        </FlexItem>
      </Flex>
    </Link>
  </FlexItem>
);

const MenuButtonItem = ({ button, to, tel, external = false }) => (
  <FlexItem>
    <Link to={to} external={external} tel={tel}>
      <Flex center>
        <FlexItem>{button}</FlexItem>
      </Flex>
    </Link>
  </FlexItem>
);

const VideoCallBox = styled(Flex)`
  width: 152px;
  height: 32px;
  border-radius: 4px;
  border: solid 4px #f7b13c;
  background-color: #ffffff;
  margin: 10px 0;
`;

const VideoCallText = styled.div`
  font-size: 16px;
  color: #666666;
`;

const VideoCallButton = () => (
  <MenuButtonItem
    button={
      <VideoCallBox center>
        <FlexItem>
          <VideoCallText>オンライン診療</VideoCallText>
        </FlexItem>
      </VideoCallBox>
    }
    to="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya"
    external
  />
);

const ReservePCRButton = () => (
  <MenuButtonItem
    button={
      <VideoCallBox center>
        <FlexItem>
          <VideoCallText>PCR検査予約</VideoCallText>
        </FlexItem>
      </VideoCallBox>
    }
    to="../contact-reservation-pcr/"
  />
);

const ContactByFormBox = styled(Flex)`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #f0862e;
  margin: 10px 0;
`;

const ContactByFormText = styled.div`
  font-size: 11px;
  color: #fcfcfc;
`;

const ContactByFormButton = () => (
  <MenuButtonItem
    button={
      <ContactByFormBox center>
        <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 28, marginRight: 10 }} color="#fff" />
        <FlexItem>
          <ContactByFormText>お問い合わせ</ContactByFormText>
        </FlexItem>
      </ContactByFormBox>
    }
    to="/contact"
  />
);

const ContactByCallBox = styled(Flex)`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #77c4a1;
  margin: 10px 0;
`;

const ContactByCallText = styled.div`
  font-size: 11px;
  color: #fcfcfc;
`;

const ContactByCallButton = () => (
  <MenuButtonItem
    button={
      <ContactByCallBox center>
        <FontAwesomeIcon
          icon={faMobileAlt}
          style={{ fontSize: 27, marginRight: 10 }}
          color="#fff"
        />
        <FlexItem>
          <ContactByCallText>電話でお問い合わせ</ContactByCallText>
        </FlexItem>
      </ContactByCallBox>
    }
    tel="tel:0334003288"
  />
);

const Close = ({ onClick, size }) => (
  <FlexItem>
    <Flex
      justifyEnd
      onClick={onClick}
      css={`
        cursor: pointer;
      `}
    >
      <FlexItem>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ fontSize: (size === 'sm' && 30) || 50, margin: '20px 50px 20px 0' }}
          color="#000"
        />
      </FlexItem>
    </Flex>
  </FlexItem>
);

const SideMenu = ({ open, onClose, size }) => (
  <Wrapper open={open} onClick={onClose}>
    <Container open={open}>
      <Flex column>
        <Close onClick={onClose} size={size} />
        <MenuItem name="HOME" to="/" />
        <MenuItem name="診療科" to="/departments" />
        <MenuItem name="内視鏡" to="/endoscopy" />
        <MenuItem name="健診・人間ドック" to="https://www.hirahata.net/" external />
        <MenuItem name="web予約" to="/reservation" />
        <MenuItem name="アクセス" to="/access" />
        <MenuItem name="受付時間" to="/schedule" />
        <MenuItem name="当院について" to="/about" />
        <MenuItem name="院長紹介" to="/dr" />
        <MenuItem name="求人情報" to="/recruit" />
        <MenuItem name="FAQ" to="/faq" />
        <MenuItem name="お問い合わせ" to="/contact" />
        <MenuItem name="プライバシーポリシー" to="/privacy_policy" />
        <VideoCallButton />
        <ReservePCRButton />
        <ContactByFormButton />
        <ContactByCallButton />
      </Flex>
    </Container>
  </Wrapper>
);

export default SideMenu;
