import styled from 'styled-components';
import { Link as LinkAsGatsby } from 'gatsby';

export const Link = styled(LinkAsGatsby)`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const A = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
