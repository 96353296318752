import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from './Link';

const ButtonFlex = styled(Flex)`
  width: ${({ size, width, fullWidth }) =>
    (fullWidth && '100%') || (width && `${width}px`) || (size === 'sm' && '110px') || '130px'};
  height: ${({ size, height }) => height || (size === 'sm' && 30) || 40}px;
  border-radius: ${({ borderRadius }) => borderRadius || 8}px;
  background-color: ${({ buttonColor }) => buttonColor};
  align-self: ${({ fullWidth }) => (fullWidth && 'stretch') || 'inherit'};
  flex-grow: ${({ fullWidth }) => (fullWidth && 1) || 0};
  cursor: pointer;
`;
const LabelText = styled.div`
  font-size: ${({ size }) => (size === 'sm' && 12) || 16}px;
  color: #ffffff;
`;

const IconButton = ({
  link,
  tel,
  external,
  label,
  fullWidth,
  size,
  width,
  height,
  borderRadius,
  icon,
  buttonColor,
  onClick = () => null,
}) => {
  const ButtonArea = () => (
    <ButtonFlex
      justifyCenter
      size={size}
      fullWidth={fullWidth}
      width={width}
      height={height}
      borderRadius={borderRadius}
      buttonColor={buttonColor}
      onClick={onClick}
    >
      <Flex center column>
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: (size === 'sm' && 16) || 23, marginRight: 5 }}
          color="#fff"
        />
      </Flex>
      <Flex center column>
        <LabelText size={size}>{label}</LabelText>
      </Flex>
    </ButtonFlex>
  );
  if (link) {
    return (
      <Link to={link} tel={tel} external={external} stretch={fullWidth}>
        <ButtonArea />
      </Link>
    );
  }
  return <ButtonArea />;
};

export default IconButton;
