import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import Logo from './common/Logo.style';
import ClinicLogoImg from '../assets/img/clinic_logo.png';

const HomeBrandFlex = styled(Flex)`
  height: ${({ size }) => (size === 'sm' && 60) || 100}px;
`;

const HomeBrandName = styled.span`
  font-size: ${({ size }) => (size === 'lg' && '36px') || '28px'};
  color: #666666;
`;

const SubTitle = styled.span`
  font-size: ${({ size }) => (size === 'lg' && '14px') || '12px'};
  color: #333333;
`;

const HomeBrand = ({ size = 'sm' }) => (
  <HomeBrandFlex column justifyCenter size={size}>
    {size !== 'sm' && (
      <FlexItem>
        <SubTitle size={size}>渋谷駅徒歩２分　コロナ後遺症・ワクチン長期副反応外来</SubTitle>
      </FlexItem>
    )}
    <Flex column center>
      <FlexItem>
        <Flex>
          <Flex center>
            <Logo
              src={ClinicLogoImg}
              css={`
                margin-right: 10px;
              `}
              size={size}
            />
          </Flex>
          <Flex center>
            <HomeBrandName size={size}>HIRAHATA CLINIC</HomeBrandName>
          </Flex>
        </Flex>
      </FlexItem>
    </Flex>
  </HomeBrandFlex>
);

export default HomeBrand;
