import React from 'react';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

import IconButton from './IconButton';

const ContactButton = ({
  link = '/contact',
  tel,
  size,
  label = 'お問い合わせ',
  fullWidth,
  buttonColor = '#77c4a1',
}) => (
  <IconButton
    fullWidth={fullWidth}
    size={size}
    link={link}
    tel={tel}
    label={label}
    icon={(tel && faPhoneVolume) || faEnvelope}
    buttonColor={buttonColor}
  />
);

export default ContactButton;
