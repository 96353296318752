import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from 'styled-components';

import { Circle } from './common/Circle.style';
import { Slash } from './common/Slash.style';

const FlexLineWrapper = styled(Flex)`
  height: 40px;
  border-bottom: solid 2px #707070;
`;
const FlexTimeWrapper = styled(Flex)`
  min-width: 115px;
`;
const TimeContainer = styled.div`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  color: #666666;
`;
const DayContainer = styled.div`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  margin: ${({ size }) => (size === 'sm' && '0 5px') || '0 9px'};
  width: 18px;
`;

const Line = ({ size, time, mon, tue, wed, thu, fri, sat, sun, holiday, fontSize }) => (
  <FlexLineWrapper alignCenter justifyBetween>
    <FlexItem grow={2}>
      <FlexTimeWrapper center>
        <FlexItem>
          <TimeContainer fontSize={fontSize}>{time}</TimeContainer>
        </FlexItem>
      </FlexTimeWrapper>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {mon}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {tue}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {wed}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {thu}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {fri}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {sat}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {sun}
      </DayContainer>
    </FlexItem>
    <FlexItem grow={1}>
      <DayContainer size={size} fontSize={fontSize}>
        {holiday}
      </DayContainer>
    </FlexItem>
  </FlexLineWrapper>
);

const langs = {
  reseptionTime: { ja: '受付時間', en: 'Reseption Time' },
  mon: { ja: '月', en: 'Mon.' },
  tue: { ja: '火', en: 'Tue.' },
  wed: { ja: '水', en: 'Wed.' },
  thu: { ja: '木', en: 'Thu.' },
  fri: { ja: '金', en: 'Fri.' },
  sat: { ja: '土', en: 'Sat.' },
  sun: { ja: '日', en: 'Sun.' },
  holiday: { ja: '祝', en: 'holidays.' },
};

const WeeklySchedule = ({ size, headerBackgroundColor = 'white', lang = 'ja' }) => (
  <Flex
    column
    css={`
      width: ${(size === 'lg' && '595px') || '100%'};
      margin-bottom: ${(size !== 'lg' && '20px') || '0'};
    `}
  >
    <FlexItem
      css={`
        background-color: ${headerBackgroundColor};
      `}
    >
      <Line
        size={size}
        time={langs.reseptionTime[lang]}
        mon={langs.mon[lang]}
        tue={langs.tue[lang]}
        wed={langs.wed[lang]}
        thu={langs.thu[lang]}
        fri={langs.fri[lang]}
        sat={langs.sat[lang]}
        sun={langs.sun[lang]}
        holiday={langs.holiday[lang]}
        fontSize={(size === 'sm' && lang === 'en' && '12px') || '16px'}
      />
    </FlexItem>
    <FlexItem>
      <Line
        size={size}
        time="09:30〜12:30"
        mon={<Circle />}
        tue={<Circle />}
        wed={<Circle />}
        thu={<Circle />}
        fri={<Circle />}
        sat={<Circle />}
        sun={<Circle />}
        holiday={<Circle />}
        fontSize={(size === 'sm' && lang === 'en' && '12px') || '16px'}
      />
    </FlexItem>
    <FlexItem>
      <Line
        size={size}
        time="14:00〜16:45"
        mon={<Circle />}
        tue={<Circle />}
        wed={<Circle />}
        thu={<Circle />}
        fri={<Circle />}
        sat={<Circle />}
        sun={<Circle />}
        holiday={<Circle />}
        fontSize={(size === 'sm' && lang === 'en' && '12px') || '16px'}
      />
    </FlexItem>
    <FlexItem>
      <Line
        size={size}
        time="18:00〜20:00"
        mon={<Circle />}
        tue={<Circle />}
        wed={<Circle />}
        thu={<Slash />}
        fri={<Circle />}
        sat={<Circle />}
        sun={<Slash />}
        holiday={<Slash />}
        fontSize={(size === 'sm' && lang === 'en' && '12px') || '16px'}
      />
    </FlexItem>
  </Flex>
);

export default WeeklySchedule;
