import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import '@openfonts/m-plus-rounded-1c_japanese';


const H2Wrapper = styled(Flex)`
  height: 60px;
`;

const StyledH2 = styled.h2`
  font-size: ${({ fontSize }) => fontSize}px;
  color: #ffffff;
  margin: 0;
  font-weight: normal;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

export const H2Background = styled.div`
  background-image: linear-gradient(to right, #f0862e 0%, #f5d100 100%);
`;

export const H2 = ({ children, size }) => (
  <H2Wrapper center>
    <StyledH2 fontSize={(size === 'sm' && 24) || 30}>{children}</StyledH2>
  </H2Wrapper>
);

const H3Wrapper = styled(Flex)`
  margin: 3em 0;
  border-radius: 25px;
  border: solid 6px #f7b13c;
  background-color: #f5d100;
  box-sizing: border-box;
`;

const StyledH3 = styled.h3`
  margin: 0 30px 0 30px;
  font-size: ${({ fontSize }) => fontSize}px;
  color: #333333;
  font-weight: normal;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

export const H3 = ({ children, size }) => (
  <H3Wrapper alignCenter>
    <StyledH3 fontSize={(size === 'sm' && 18) || 24}>{children}</StyledH3>
  </H3Wrapper>
);

const H4Wrapper = styled(Flex)`
  margin: 2em 0;
  border-radius: 25px;
  background-color: #f0862e;
  box-sizing: border-box;
`;

const StyledH4 = styled.h4`
  margin: 0 20px 0 20px;
  font-size: ${({ fontSize }) => fontSize}px;
  color: #ffffff;
  font-weight: normal;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

export const H4 = ({ children, size }) => (
  <H4Wrapper alignCenter>
    <StyledH4 fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4>
  </H4Wrapper>
);

const H5Wrapper = styled(Flex)`
  margin: 10px 0;
  border-radius: 25px;
  border: solid 3px #f7b13c;
  background-color: #f5d100;
  box-sizing: border-box;
`;

const StyledH5 = styled.h5`
  margin: 0 20px 0 20px;
  font-size: ${({ fontSize }) => fontSize}px;
  color: #3b4043;
  font-weight: normal;
`;

export const H5 = ({ children, size }) => (
  <H5Wrapper alignCenter>
    <StyledH5 fontSize={(size === 'sm' && 10) || 16}>{children}</StyledH5>
  </H5Wrapper>
);
