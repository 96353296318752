import { createGlobalStyle } from 'styled-components';
import '@openfonts/m-plus-rounded-1c_japanese';
import '@openfonts/noto-sans-jp_japanese';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Helvetica Neue",
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "Yu Gothic UI",
    "Yu Gothic",
    "Noto Sans JP",
    Arial,
    Meiryo,
    sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    transform: rotate(0.03deg);
    color:#333;
  }
  a {
    text-decoration: none;
    color:#666;
  }
`;