import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-regular-svg-icons';

const Title = styled.span`
  font-size: 13px;
  color: #ce3a0d;
`;

const langs = {
  close: { ja: '休診日 日曜・祝日', en: 'Closed: Sunday & Public Holiday' },
};

const ClosedDayLabel = ({ lang = 'ja' }) => (
  <Flex
    css={`
      height: 28px;
    `}
  >
    <Flex column center>
      <FontAwesomeIcon
        icon={faCalendarTimes}
        style={{ width: 18, marginRight: 5 }}
        color="#d13a00"
      />
    </Flex>
    <Flex center>
      <Title>{langs.close[lang]}</Title>
    </Flex>    

  </Flex>
);

export default ClosedDayLabel;
