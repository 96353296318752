import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const Title = styled.span`
  font-size: 13px;
  color: #666666;
`;

const langs = {
  telemed: { ja: 'オンライン診療', en: 'Our Online Telemedicine Service' },
  anytime: { ja: 'は年中無休', en: ' is open 365 days' },
};

const Online365Label = ({ lang = 'ja' }) => (
  <Flex
    css={`
      height: 28px;
    `}
  >
  </Flex>
);

export default Online365Label;
