import React from 'react';
import { Link as StyledLink, A as StyledA } from './common/Link.style';

const Link = ({ to, external, tel, children, stretch, onClick }) => {
  if (tel)
    return (
      <StyledA
        onClick={onClick}
        href={tel}
        css={`
          align-self: ${(stretch && 'stretch') || 'inherit'};
          flex-grow: ${(stretch && 1) || 0};
        `}
      >
        {children}
      </StyledA>
    );
  return external ? (
    <StyledA
      onClick={onClick}
      href={to}
      target="_blank"
      css={`
        align-self: ${(stretch && 'stretch') || 'inherit'};
        flex-grow: ${(stretch && 1) || 0};
      `}
    >
      {children}
    </StyledA>
  ) : (
    <StyledLink
      onClick={onClick}
      to={to}
      css={`
        align-self: ${(stretch && 'stretch') || 'inherit'};
        flex-grow: ${(stretch && 1) || 0};
      `}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
